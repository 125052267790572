<script setup>
import {Head, Link, useForm} from '@inertiajs/vue3';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import FloatingLabelInput from "@/Components/FloatingLabelInput.vue";
import MainLayout from "@/Layouts/MainLayout.vue";
import LoginLink from '@/../../vendor/spatie/laravel-login-link/resources/js/login-link.vue';
import MyCaliLogoLarge from "@/Components/icons/MyCaliLogoLarge.vue";
import {computed, onMounted, onUnmounted} from "vue";
import {useTranslations} from "@/composables/student/useTranslations.js";


let intervalId;
const {translations} = useTranslations();

onMounted(() => {
    refreshCsrfToken();
    intervalId = setInterval(refreshCsrfToken, 1000 * 60 * 30); // Refresh every 30 minutes
});

onUnmounted(() => {
    clearInterval(intervalId); // Clear the interval when the component is unmounted
});

function refreshCsrfToken() {
    axios.get('/csrf-token').then(response => {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.csrfToken;
    }).catch(error => {
        console.error('Error fetching CSRF token:', error);
    });
}

defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const isFormFilled = computed(() => {
    return form.email?.trim() !== '' && form.password?.trim() !== '';
});

const submit = () => {
    form.transform(data => ({
        ...data,
        remember: form.remember ? 'on' : '',
    })).post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <Head :title="translations['myCali Login']"/>

    <MainLayout>
        <div class="flex justify-center">
            <MyCaliLogoLarge/>
        </div>
        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>
        <div class="mt-8 px-4">
            <form @submit.prevent="submit">
                <div class="text-cali-gold text-xs italic leading-none mb-4">
                    Vous avez déjà un compte ?
                </div>
                
                
                <div class="font-bold ">
                    Se connecter à mon compte.
                </div>
                <FloatingLabelInput
                    id="email"
                    v-model="form.email"
                    type="text"
                    :label="translations['User ID or Email or WhatsApp']"
                    :error="form.errors.email"
                    required
                    autofocus
                    autocomplete="username"
                />

                <FloatingLabelInput
                    class="mt-4"
                    id="password"
                    v-model="form.password"
                    type="password"
                    :label="translations['Password']"
                    :error="form.errors.password"
                    required
                    autocomplete="current-password"
                />

                <div class="flex items-center justify-center mt-4">
                    <PrimaryButton class="justify-center ms-4 w-187 button px-6 py-3 " :class="{ 'opacity-25': form.processing }"
                                   :disabled="!isFormFilled || form.processing"
                                    >
                                   {{ translations['Login'] }}
                    </PrimaryButton>
                </div>
            </form>
            <div class="text-cali-gold text-xs italic leading-none mt-6">
                C'est la première fois que vous visitez myCALI ?
            </div>
            <div class="font-bold mt-4">
                Créer mon compte.
            </div>
            
            <div class="flex items-center justify-center mt-4">
                <Link :href="route('register')">
                    <button class="border font-bold text-cali-gray border-cali-gray justify-center ms-4 w-187 button px-6 py-2 " :class="{ 'opacity-25': form.processing }"
                    
                    >
                        Créer un compte
                    </button>
                </Link>
            </div>
            
            
            
            <div class="flex flex-col text-white mt-8 text-center">
                <Link :href="route('password.request')"
                      class="decoration-1 underline-offset-2 underline text-white hover:text-cali-blue rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {{ translations['I forgot my'] }} {{ translations['password'] }}
                </Link>
            </div>
            
            <div class="flex flex-col text-white mt-8 text-center">
                <Link :href="route('privacy-policy')" class="decoration-1 underline-offset-2 underline mb-2 hover:text-gray-300">{{ translations['Privacy Policy'] }}</Link>
                <Link :href="route('code-of-conduct')" class="decoration-1 underline-offset-2 underline mb-2 hover:text-gray-300">Code de conduite de CALI</Link>
            </div>
        </div>
        <LoginLink v-if="$page.props.environment == 'local'" label="Login as Admin" email="gorguner@gmail.com" class="pb-3 text-red-500" :redirect-url="route('dashboard')"/>
        <LoginLink v-if="$page.props.environment == 'local'" label="Login as Student" email="gorguner+student@gmail.com" class="pb-3 text-red-500"/>
        <LoginLink v-if="$page.props.environment == 'local'" label="Login as Student with grades" email="adjanimbombo@gmail.com" class="pb-3 text-red-500"/>
        
        
        <LoginLink v-if="$page.props.environment == 'local'" label="Login as Teacher" email="gorguner+teacher@gmail.com" class="pb-3 text-red-500"/>

    </MainLayout>
</template>
